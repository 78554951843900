// vendors
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Helmet } from 'react-helmet';
// views
import { FormattedMessage, useIntl } from 'react-intl';
import OutlinedButton from '../components/Button/OutlinedButton';
// Styles
import UnderlinedButton, {
  classes as LinkClassNames,
} from '../components/Button/UnderlinedButton';
import InView from '../components/InView/InVew';
// components
import Layout from '../components/Layout/Layout';
import AubergeLogotype from '../components/Logo/AubergeLogotype';
import AubergePictogram from '../components/Logo/AubergePictogram';
import Text from '../components/Text';
import Title from '../components/Title';
import VideoSection from '../components/VideoSection';
// Videos
import intro700mp4 from '../videos/introAuberge700k.mp4';
import HomePageAbout from '../views/HomePageAbout';
import HotelCarousel from '../views/HotelCarousel/HotelCarousel';
import ScrollingText from '../views/ScrollingText';

import PopupComponent from '../components/PopupComponent/PopupComponent';
import { convertContentToString } from '../libraries/ContentToString';

import CookieConsentCSS from '../components/CookieConsent/CookieConsentCSS';
import CookieConsent, {
  doNecessaryCookieAuthorized,
  doAnalyticCookieAuthorized,
  doAdsCookieAuthorized,
} from '../components/CookieConsent/CookieConsent';


const HistoryText = (
  <Text className="mx-auto max-w-prose">
    <InView>
      <Title level={3}>Over 100 years of history</Title>
    </InView>

    <InView>
      <p>
        Built in 1878 when the village was founded only 16 years prior, Auberge
        sur Mer has borne witness to the history of Notre-Dame-du-Portage.
      </p>
    </InView>
  </Text>
);

const WhatToDoText = (
  <>
    <InView>
      <Title level={3}>What to do in Notre-Dame-du-Portage?</Title>
    </InView>

    <Text className="max-w-prose">
      <InView>
        <p>
          Walking and biking are the best ways to discover the area. All along
          the Route verte bike path, you will come across stunning viewpoints
          that will immerse your senses and give you the opportunity to discover
          the spectacular riverscapes and their diverse wildlife and plants.
        </p>

        <p>
          The public beach of Notre-Dame-du-Portage is the heart of the village.
          The municipal pool is renowned for its heated salt water that appeals
          to people of all ages.
        </p>

        <p>
          For more information, visit:{' '}
          <a href="https://notredameduportage.ca" className={LinkClassNames}>
            notredameduportage.ca
          </a>
        </p>
      </InView>
    </Text>
  </>
);

const StoreText = (
  <>
    <Title level={3}>Boutique</Title>

    <Text>
      <StaticImage
        src="../images/auberge/Auberge-sur-mer-18.jpg"
        width={832}
        className="m-8 lg:float-right lg:m-4 lg:mt-0 lg:mr-0 lg:max-w-[448px] lg:fluid-w-[18/28]"
        sizes="(max-width: 1023px) 100vw,(max-width: 1920px) 50vw, 832px"
        alt={``}
      />
      <p>
        Auberge sur Mer features a quaint boutique where you can find souvenirs
        from our region and made mostly by local producers. Whether it is
        amazing delicacies, a variety of beauty products, or different items to
        decorate your kitchen or home, you will uncover finds that will please
        and remind you of your stay with us.
      </p>
    </Text>
  </>
);

const alternativesLanguages = [
  {
    locale: 'fr',
    href: '/',
  },
];

function HomePage({ data }) {
  const intl = useIntl();

  const popupMessage = convertContentToString(data.businessCloseMessage?.portableText);
  
  return (
    
    <Layout className="pb-40" alternativesLanguages={alternativesLanguages}>
      <CookieConsentCSS></CookieConsentCSS>

      <CookieConsent></CookieConsent>

      {popupMessage.length > 10 && <PopupComponent message={popupMessage} />}
      
      <Helmet>
        <link rel="preload" href={intro700mp4} as="video" type="video/mp4" />
      </Helmet>

      <div className="text-center">
        <h1 className="inline-block px-12 mt-32 mb-16 max-w-[32rem] md:mt-16">
          <span className="sr-only">Auberge sur Mer</span>
          <AubergeLogotype className="w-full" role="presentation" />
        </h1>
      </div>

      <div className="container mx-auto">
        <VideoSection desktopMp4={intro700mp4} />
      </div>

      <section
        id={intl.formatMessage({ defaultMessage: 'about' })}
        className="my-40 mx-auto space-y-14 max-w-prose font-title text-center scroll-mt-24 px-container fluid-text-[1.25/3]"
      >
        <InView className="mx-auto w-20">
          <AubergePictogram animate />
        </InView>

        <InView>
          <p>
            A centennial inn located directly on the St. Lawrence River, in the
            magnificent village of Notre-Dame-du-Portage, near Rivière-du-Loup,
            Auberge sur Mer is at the crossroads of the Maritimes and Gaspésie.
            Admire a corner of the country that will make you dream, and explore
            it on foot or by bicycle. Enjoy a journey to the fascinating islands
            of the St. Lawrence River or just sit back and relax.
          </p>
        </InView>
      </section>

      <HomePageAbout
        historyText={HistoryText}
        whatToDoText={WhatToDoText}
        storeText={StoreText}
      />

      <div className="my-[25vh]">
        <ScrollingText />
      </div>

      <section
        id={intl.formatMessage({ defaultMessage: 'accommodation' })}
        className="scroll-mt-24"
      >
        <div className="container mx-auto text-center">
          <InView>
            <Title level={2}>Accommodations</Title>
          </InView>

          <InView>
            <OutlinedButton
              href="https://secure.reservit.com/fo/booking/58/203884?specialMode=default&langcode=FR&partid=0&custid=58&hotelid=203884&m=booking"
              target="_blank"
            >
              <FormattedMessage defaultMessage="Chambres et tarifs" />
            </OutlinedButton>
          </InView>
        </div>

        <InView className="fluid-my-[4/6]">
          <HotelCarousel />
        </InView>

        <InView className="container flex flex-col gap-8 justify-between items-center mx-auto md:flex-row md:items-start fluid-my-[4/8]">
          <Text className="flex-1 max-w-prose text-center">
            <Title level={3}>At the motel</Title>

            <p>
              Just steps away from the inn, motels offer private balconies with
              a breathtaking views of the river.
            </p>

            <UnderlinedButton href="https://secure.reservit.com/fo/booking/58/203884?specialMode=default&langcode=FR&partid=0&custid=58&hotelid=203884&m=booking">
              Book now
            </UnderlinedButton>
          </Text>

          <Text className="flex-1 max-w-prose text-center">
            <Title level={3}>At the inn</Title>

            <p>
              Located on the 2nd and 3rd floors of a charming century-old
              building, the inn’s rooms will surround you in history and
              heritage.
            </p>

            <UnderlinedButton href="https://secure.reservit.com/fo/booking/58/203884?specialMode=default&langcode=FR&partid=0&custid=58&hotelid=203884&m=booking">
              Book now
            </UnderlinedButton>
          </Text>

          <Text className="flex-1 max-w-prose text-center">
            <Title level={3}>At the cottage</Title>

            <p>
              Situated a few steps away from the inn and directly on the river’s
              banks, our cottages offer complete and very spacious
              accommodations, including two bedrooms, a complete kitchen and a
              large patio with a partial view on the river. This is the ideal
              option to enjoy all the conveniences of the inn—with more privacy
              and autonomy.
            </p>

            <UnderlinedButton href="https://secure.reservit.com/fo/booking/58/203884?specialMode=default&langcode=FR&partid=0&custid=58&hotelid=203884&m=booking">
              Book now
            </UnderlinedButton>
          </Text>
        </InView>
      </section>

      <div className="container mx-auto fluid-my-[4/8]">
        <StaticImage
          width={1664}
          src="../images/auberge/Auberge-sur-mer-21.jpg"
          role="presentation"
          sizes="(max-width: 1920px) 100vw, 1664px"
          className="w-full"
          alt={``}
        />
      </div>

      <section
        id={intl.formatMessage({ defaultMessage: 'restaurant' })}
        className="container gap-x-4 mx-auto lg:flex lg:justify-start lg:items-center fluid-my-[4/8]"
      >
        <Text className="text-center lg:text-left">
          <InView>
            <Title level={2}>Restaurant</Title>
          </InView>

          <InView>
            <p>
              Our restaurant, April, offers a cuisine inspired by local and
              seasonal products.
            </p>
          </InView>

          <InView className="fluid-pt-[1.5/3]">
            <OutlinedButton to="/restaurant">
              <FormattedMessage defaultMessage="En savoir plus" />
            </OutlinedButton>
          </InView>
        </Text>

        <Link to="/en/restaurant" className="block my-12 lg:hidden">
          <StaticImage
            src="../images/restaurant-april-mobile.png"
            className="block mx-auto"
            width={904}
            sizes="(max-width: 1024px) 100vw, 904px"
            alt={``}
          />
        </Link>

        <Link
          to="/en/restaurant"
          className="hidden grow my-0 text-center lg:block"
        >
          <StaticImage
            src="../images/restaurant-april-desktop.png"
            width={768}
            className="block mx-auto fluid-w-[28/40]"
            sizes="(max-width: 1920px) 50vw, 640px"
            alt={``}
          />
        </Link>
      </section>
    </Layout>
  );
}

export default HomePage;



HomePage.propTypes = {
  data: PropTypes.shape({
    breakfastMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    dinerMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    snackMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    
    businessCloseMessage: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    privacyRules: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    cookieRules: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    openingHours: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    /*
    */
  }).isRequired,
};

export const query = graphql`
  query {
    breakfastMenu: sanityBreakfastMenu(_id: { eq: "breakfastMenu" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    dinerMenu: sanityDinerMenu(_id: { eq: "dinerMenu" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    snackMenu: sanitySnackMenu(_id: { eq: "snackMenu" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    businessCloseMessage: sanityBusinessCloseMessage(_id: { eq: "businessCloseMessage" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    privacyRules: sanityPrivacyRules(_id: { eq: "privacyRules" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    cookeyRules: sanityCookieRules(_id: { eq: "cookieRules" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
    openingHours: sanityOpeningHours(_id: { eq: "openingHours" }) {
      portableText: _rawEn(resolveReferences: { maxDepth: 10 })
    }
  }
`;

